import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroMini from "../components/heroSmall"

const TermsAndConditions = () => (
  <Layout>
    <Seo title="Terms and conditions" />
    <HeroMini title="Terms and conditions"/>
    <main className="section has-text-black">
    <div className="container">
        <div className="columns">
            <div className="column has-text-black">
            <p className="is-size-6 mt-3">Please read these Terms and Conditions carefully before using the http://www.fusion.je 
                website operated by Fusion Development. Your access to and use of the Service is 
                conditioned on your acceptance of and compliance with these Terms. These Terms apply 
                to all visitors, users and others who access or use the Service</p><br/>
            <p className="is-size-6">By accessing or using the Service you agree to be bound by these Terms. If you disagree 
                with any part of the terms then you may not access the Service.</p>
            <p className="is-size-3 mt-6">Content</p>
            <p className="is-size-6 mt-3">Our website allows you to link, share and otherwise make available certain information, 
                text, graphics, videos, or other material. We are not responsible for the content or 
                reliability of the websites you share or link to and do not necessarily endorse the 
                views expressed within them.</p>
            <p className="is-size-3 mt-6">Links To Other Web Sites</p>
            <p className="is-size-6 mt-3">Our Service may contain links to third-party web sites or services that are not owned 
                or controlled by Fusion Development.</p><br/>
            <p className="is-size-6">Fusion Development has no control over, and assumes no responsibility for, the content, 
                privacy policies, or practices of any third party web sites or services. You further 
                acknowledge and agree that Fusion Development shall not be responsible or liable, 
                directly or indirectly, for any damage or loss caused or alleged to be caused by or 
                in connection with use of or reliance on any such content, goods or services available 
                on or through any such web sites or services.</p>
            <p className="is-size-3 mt-6">Changes</p>
            <p className="is-size-6 mt-3">We reserve the right, at our sole discretion, to modify or replace these Terms at any 
                time. If a revision is material we will try to provide at least 30 days' notice prior 
                to any new terms taking effect. What constitutes a material change will be determined 
                at our sole discretion.</p><br/>
            <p className="is-size-3 mt-3">Contact Us</p>
            <p className="is-size-6 mb-6">If you have any questions about these Terms, please contact us.</p>
            </div>
        </div>
    </div>
    </main>
  </Layout>
)

export default TermsAndConditions;
